<template>
	<v-sheet min-height="300" class="grey lighten-5">
		<p class="text-caption">{{ userStore.user.createdAt }}</p>
	</v-sheet>
</template>

<script>
	import { useUserStore } from "@/store/users";
	export default {
		name: "UserActivity",
		activated() {
			//console.log(this.$route.params.id);
		},
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
	};
</script>
