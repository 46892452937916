/* eslint-disable */
import { defineStore } from "pinia";
import { useSessionStore } from "@/store/session";
import { db, functions } from "@/services/firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const useUserStore = defineStore({
    id: "users",
    state: () => ({
        form: {
            displayName: "",
            email: "",
        },
        user: {},
        claims: {
            accessList: [],
        },
        claimsModified: false,
        userList: [],
        loading: false,
    }),
    actions: {
        async sendEmailVerificationLink() {
            try {
                this.loading = true;
                const data = { to_uid: this.user.id };
                const call = httpsCallable(
                    functions,
                    "sendEmailVerificationLink"
                );
                const res = await call(data);
                useSessionStore().showSuccess();
                this.loading = false;
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async createUser() {
            try {
                const call = httpsCallable(functions, "createUser");
                const res = await call(this.form);
                this.userList.push(this.form);
                useSessionStore().showSuccess();
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async deleteUser(uid) {
            try {
                const call = httpsCallable(functions, "deleteUser");
                await call(uid);
                useSessionStore().showSuccess();
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async fetchUsers() {
            this.userList = [];
            const users = await getDocs(collection(db, "users"));
            this.userList = users.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
        },
        async fetchUser(uid) {
            if (uid && this.user && this.user.id === uid) return;
            try {
                const docSnap = await getDoc(doc(db, "users", uid));
                this.user = { ...docSnap.data() };
                this.user.id = docSnap.id;
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async fetchCustomUserClaims() {
            try {
                const call = httpsCallable(functions, "getCustomUserClaims");
                const claims = await call(this.user.id);
                //console.log("CLAIMS", claims);
                this.claims = claims.data;
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async updateCustomUserClaims() {
            const data = {
                uid: this.user.id,
                claims: this.claims,
            };
            const call = httpsCallable(functions, "setCustomUserClaims");
            try {
                await call(data);
                useSessionStore().showSuccess();
                this.setClaimsModified(false);
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        setClaimsModified(v) {
            this.claimsModified = v;
        },
    },
});
